export const genres = [
  {
    name: "Acoustic",
    color: "rgba(192, 194, 66, 0.6)",
    colorHover: "rgba(192, 194, 66, 1)",
    textColor: "#000",
  },
  {
    name: "Afrobeat",
    color: "rgba(86, 131, 200, 0.6)",
    colorHover: "rgba(86, 131, 200, 1)",
    textColor: "#fff",
  },
  {
    name: "Alt-rock",
    color: "rgba(66, 194, 153, 0.6)",
    colorHover: "rgba(66, 194, 153, 1)",
    textColor: "#000",
  },
  {
    name: "Alternative",
    color: "rgba(190, 207, 94, 0.6)",
    colorHover: "rgba(190, 207, 94, 1)",
    textColor: "#000",
  },
  {
    name: "Ambient",
    color: "rgba(64, 191, 64, 0.6)",
    colorHover: "rgba(64, 191, 64, 1)",
    textColor: "#000",
  },
  {
    name: "Anime",
    color: "rgba(64, 106, 191, 0.6)",
    colorHover: "rgba(64, 106, 191, 1)",
    textColor: "#fff",
  },
  {
    name: "Black-metal",
    color: "rgba(94, 207, 186, 0.6)",
    colorHover: "rgba(94, 207, 186, 1)",
    textColor: "#000",
  },
  {
    name: "Bluegrass",
    color: "rgba(196, 69, 128, 0.6)",
    colorHover: "rgba(196, 69, 128, 1)",
    textColor: "#fff",
  },
  {
    name: "Blues",
    color: "rgba(197, 88, 202, 0.6)",
    colorHover: "rgba(197, 88, 202, 1)",
    textColor: "#000",
  },
  {
    name: "Bossanova",
    color: "rgba(137, 69, 196, 0.6)",
    colorHover: "rgba(137, 69, 196, 1)",
    textColor: "#fff",
  },
  {
    name: "Brazil",
    color: "rgba(196, 94, 69, 0.6)",
    colorHover: "rgba(196, 94, 69, 1)",
    textColor: "#fff",
  },
  {
    name: "Breakbeat",
    color: "rgba(94, 207, 149, 0.6)",
    colorHover: "rgba(94, 207, 149, 1)",
    textColor: "#000",
  },
  {
    name: "British",
    color: "rgba(194, 175, 66, 0.6)",
    colorHover: "rgba(194, 175, 66, 1)",
    textColor: "#000",
  },
  {
    name: "Cantopop",
    color: "rgba(74, 201, 146, 0.6)",
    colorHover: "rgba(74, 201, 146, 1)",
    textColor: "#000",
  },
  {
    name: "Chicago-house",
    color: "rgba(192, 194, 66, 0.6)",
    colorHover: "rgba(192, 194, 66, 1)",
    textColor: "#000",
  },
  {
    name: "Children",
    color: "rgba(108, 199, 72, 0.6)",
    colorHover: "rgba(108, 199, 72, 1)",
    textColor: "#000",
  },
  {
    name: "Chill",
    color: "rgba(94, 69, 196, 0.6)",
    colorHover: "rgba(94, 69, 196, 1)",
    textColor: "#fff",
  },
  {
    name: "Classical",
    color: "rgba(94, 115, 207, 0.6)",
    colorHover: "rgba(94, 115, 207, 1)",
    textColor: "#fff",
  },
  {
    name: "Club",
    color: "rgba(191, 128, 64, 0.6)",
    colorHover: "rgba(191, 128, 64, 1)",
    textColor: "#000",
  },
  {
    name: "Comedy",
    color: "rgba(207, 111, 94, 0.6)",
    colorHover: "rgba(207, 111, 94, 1)",
    textColor: "#000",
  },
  {
    name: "Country",
    color: "rgba(201, 74, 87, 0.6)",
    colorHover: "rgba(201, 74, 87, 1)",
    textColor: "#fff",
  },
  {
    name: "Dance",
    color: "rgba(92, 198, 83, 0.6)",
    colorHover: "rgba(92, 198, 83, 1)",
    textColor: "#000",
  },
  {
    name: "Dancehall",
    color: "rgba(83, 74, 201, 0.6)",
    colorHover: "rgba(83, 74, 201, 1)",
    textColor: "#fff",
  },
  {
    name: "Death-metal",
    color: "rgba(205, 125, 91, 0.6)",
    colorHover: "rgba(205, 125, 91, 1)",
    textColor: "#000",
  },
  {
    name: "Deep-house",
    color: "rgba(202, 197, 88, 0.6)",
    colorHover: "rgba(202, 197, 88, 1)",
    textColor: "#000",
  },
  {
    name: "Detroit-techno",
    color: "rgba(88, 202, 178, 0.6)",
    colorHover: "rgba(88, 202, 178, 1)",
    textColor: "#000",
  },
  {
    name: "Disco",
    color: "rgba(125, 91, 205, 0.6)",
    colorHover: "rgba(125, 91, 205, 1)",
    textColor: "#fff",
  },
  {
    name: "Disney",
    color: "rgba(191, 106, 64, 0.6)",
    colorHover: "rgba(191, 106, 64, 1)",
    textColor: "#fff",
  },
  {
    name: "Drum-and-bass",
    color: "rgba(196, 69, 128, 0.6)",
    colorHover: "rgba(196, 69, 128, 1)",
    textColor: "#fff",
  },
  {
    name: "Dub",
    color: "rgba(199, 72, 171, 0.6)",
    colorHover: "rgba(199, 72, 171, 1)",
    textColor: "#fff",
  },
  {
    name: "Dubstep",
    color: "rgba(150, 199, 72, 0.6)",
    colorHover: "rgba(150, 199, 72, 1)",
    textColor: "#000",
  },
  {
    name: "Edm",
    color: "rgba(205, 163, 91, 0.6)",
    colorHover: "rgba(205, 163, 91, 1)",
    textColor: "#000",
  },
  {
    name: "Electro",
    color: "rgba(205, 144, 91, 0.6)",
    colorHover: "rgba(205, 144, 91, 1)",
    textColor: "#000",
  },
  {
    name: "Electronic",
    color: "rgba(167, 74, 201, 0.6)",
    colorHover: "rgba(167, 74, 201, 1)",
    textColor: "#fff",
  },
  {
    name: "Emo",
    color: "rgba(207, 94, 171, 0.6)",
    colorHover: "rgba(207, 94, 171, 1)",
    textColor: "#000",
  },
  {
    name: "Folk",
    color: "rgba(86, 131, 200, 0.6)",
    colorHover: "rgba(86, 131, 200, 1)",
    textColor: "#fff",
  },
  {
    name: "Forro",
    color: "rgba(64, 170, 191, 0.6)",
    colorHover: "rgba(64, 170, 191, 1)",
    textColor: "#000",
  },
  {
    name: "Funk",
    color: "rgba(167, 74, 201, 0.6)",
    colorHover: "rgba(167, 74, 201, 1)",
    textColor: "#fff",
  },
  {
    name: "Garage",
    color: "rgba(108, 199, 72, 0.6)",
    colorHover: "rgba(108, 199, 72, 1)",
    textColor: "#000",
  },
  {
    name: "Gospel",
    color: "rgba(91, 114, 205, 0.6)",
    colorHover: "rgba(91, 114, 205, 1)",
    textColor: "#fff",
  },
  {
    name: "Goth",
    color: "rgba(196, 94, 69, 0.6)",
    colorHover: "rgba(196, 94, 69, 1)",
    textColor: "#fff",
  },
  {
    name: "Grindcore",
    color: "rgba(202, 159, 88, 0.6)",
    colorHover: "rgba(202, 159, 88, 1)",
    textColor: "#000",
  },
  {
    name: "Groove",
    color: "rgba(86, 200, 135, 0.6)",
    colorHover: "rgba(86, 200, 135, 1)",
    textColor: "#000",
  },
  {
    name: "Grunge",
    color: "rgba(200, 86, 112, 0.6)",
    colorHover: "rgba(200, 86, 112, 1)",
    textColor: "#fff",
  },
  {
    name: "Guitar",
    color: "rgba(64, 191, 170, 0.6)",
    colorHover: "rgba(64, 191, 170, 1)",
    textColor: "#000",
  },
  {
    name: "Happy",
    color: "rgba(193, 201, 74, 0.6)",
    colorHover: "rgba(193, 201, 74, 1)",
    textColor: "#000",
  },
  {
    name: "Hard-rock",
    color: "rgba(132, 66, 194, 0.6)",
    colorHover: "rgba(132, 66, 194, 1)",
    textColor: "#fff",
  },
  {
    name: "Hardcore",
    color: "rgba(200, 135, 86, 0.6)",
    colorHover: "rgba(200, 135, 86, 1)",
    textColor: "#000",
  },
  {
    name: "Hardstyle",
    color: "rgba(150, 200, 86, 0.6)",
    colorHover: "rgba(150, 200, 86, 1)",
    textColor: "#000",
  },
  {
    name: "Heavy-metal",
    color: "rgba(112, 83, 198, 0.6)",
    colorHover: "rgba(112, 83, 198, 1)",
    textColor: "#fff",
  },
  {
    name: "Hip-hop",
    color: "rgba(198, 169, 83, 0.6)",
    colorHover: "rgba(198, 169, 83, 1)",
    textColor: "#000",
  },
  {
    name: "Holidays",
    color: "rgba(194, 66, 192, 0.6)",
    colorHover: "rgba(194, 66, 192, 1)",
    textColor: "#fff",
  },
  {
    name: "Honky-tonk",
    color: "rgba(74, 193, 201, 0.6)",
    colorHover: "rgba(74, 193, 201, 1)",
    textColor: "#000",
  },
  {
    name: "House",
    color: "rgba(131, 200, 86, 0.6)",
    colorHover: "rgba(131, 200, 86, 1)",
    textColor: "#000",
  },
  {
    name: "Idm",
    color: "rgba(158, 69, 196, 0.6)",
    colorHover: "rgba(158, 69, 196, 1)",
    textColor: "#fff",
  },
  {
    name: "Industrial",
    color: "rgba(94, 171, 207, 0.6)",
    colorHover: "rgba(94, 171, 207, 1)",
    textColor: "#000",
  },
  {
    name: "J-dance",
    color: "rgba(149, 191, 64, 0.6)",
    colorHover: "rgba(149, 191, 64, 1)",
    textColor: "#000",
  },
  {
    name: "J-idol",
    color: "rgba(99, 72, 199, 0.6)",
    colorHover: "rgba(99, 72, 199, 1)",
    textColor: "#fff",
  },
  {
    name: "J-pop",
    color: "rgba(74, 129, 201, 0.6)",
    colorHover: "rgba(74, 129, 201, 1)",
    textColor: "#fff",
  },
  {
    name: "J-rock",
    color: "rgba(86, 131, 200, 0.6)",
    colorHover: "rgba(86, 131, 200, 1)",
    textColor: "#fff",
  },
  {
    name: "Jazz",
    color: "rgba(83, 198, 188, 0.6)",
    colorHover: "rgba(83, 198, 188, 1)",
    textColor: "#000",
  },
  {
    name: "K-pop",
    color: "rgba(198, 83, 131, 0.6)",
    colorHover: "rgba(198, 83, 131, 1)",
    textColor: "#fff",
  },
  {
    name: "Kids",
    color: "rgba(92, 83, 198, 0.6)",
    colorHover: "rgba(92, 83, 198, 1)",
    textColor: "#fff",
  },
  {
    name: "Latin",
    color: "rgba(74, 150, 201, 0.6)",
    colorHover: "rgba(74, 150, 201, 1)",
    textColor: "#000",
  },
  {
    name: "Latino",
    color: "rgba(194, 66, 192, 0.6)",
    colorHover: "rgba(194, 66, 192, 1)",
    textColor: "#fff",
  },
  {
    name: "Malay",
    color: "rgba(191, 64, 149, 0.6)",
    colorHover: "rgba(191, 64, 149, 1)",
    textColor: "#fff",
  },
  {
    name: "Mandopop",
    color: "rgba(86, 170, 200, 0.6)",
    colorHover: "rgba(86, 170, 200, 1)",
    textColor: "#000",
  },
  {
    name: "Metal",
    color: "rgba(171, 207, 94, 0.6)",
    colorHover: "rgba(171, 207, 94, 1)",
    textColor: "#000",
  },
  {
    name: "Metal-misc",
    color: "rgba(69, 107, 196, 0.6)",
    colorHover: "rgba(69, 107, 196, 1)",
    textColor: "#fff",
  },
  {
    name: "Metalcore",
    color: "rgba(116, 69, 196, 0.6)",
    colorHover: "rgba(116, 69, 196, 1)",
    textColor: "#fff",
  },
  {
    name: "Minimal-techno",
    color: "rgba(134, 207, 94, 0.6)",
    colorHover: "rgba(134, 207, 94, 1)",
    textColor: "#000",
  },
  {
    name: "Mpb",
    color: "rgba(88, 202, 178, 0.6)",
    colorHover: "rgba(88, 202, 178, 1)",
    textColor: "#000",
  },
  {
    name: "Opera",
    color: "rgba(72, 150, 199, 0.6)",
    colorHover: "rgba(72, 150, 199, 1)",
    textColor: "#000",
  },
  {
    name: "Pagode",
    color: "rgba(188, 74, 201, 0.6)",
    colorHover: "rgba(188, 74, 201, 1)",
    textColor: "#fff",
  },
  {
    name: "Party",
    color: "rgba(91, 171, 205, 0.6)",
    colorHover: "rgba(91, 171, 205, 1)",
    textColor: "#000",
  },
  {
    name: "Piano",
    color: "rgba(72, 86, 199, 0.6)",
    colorHover: "rgba(72, 86, 199, 1)",
    textColor: "#fff",
  },
  {
    name: "Pop",
    color: "rgba(72, 199, 142, 0.6)",
    colorHover: "rgba(72, 199, 142, 1)",
    textColor: "#000",
  },
  {
    name: "Post-dubstep",
    color: "rgba(191, 149, 64, 0.6)",
    colorHover: "rgba(191, 149, 64, 1)",
    textColor: "#000",
  },
  {
    name: "Power-pop",
    color: "rgba(199, 72, 108, 0.6)",
    colorHover: "rgba(199, 72, 108, 1)",
    textColor: "#fff",
  },
  {
    name: "Progressive-house",
    color: "rgba(86, 200, 97, 0.6)",
    colorHover: "rgba(86, 200, 97, 1)",
    textColor: "#000",
  },
  {
    name: "Psych-rock",
    color: "rgba(132, 66, 194, 0.6)",
    colorHover: "rgba(132, 66, 194, 1)",
    textColor: "#fff",
  },
  {
    name: "Punk",
    color: "rgba(87, 201, 74, 0.6)",
    colorHover: "rgba(87, 201, 74, 1)",
    textColor: "#000",
  },
  {
    name: "Punk-rock",
    color: "rgba(86, 200, 192, 0.6)",
    colorHover: "rgba(86, 200, 192, 1)",
    textColor: "#000",
  },
  {
    name: "R-n-b",
    color: "rgba(149, 191, 64, 0.6)",
    colorHover: "rgba(149, 191, 64, 1)",
    textColor: "#000",
  },
  {
    name: "Rainy-day",
    color: "rgba(200, 86, 170, 0.6)",
    colorHover: "rgba(200, 86, 170, 1)",
    textColor: "#fff",
  },
  {
    name: "Reggae",
    color: "rgba(152, 207, 94, 0.6)",
    colorHover: "rgba(152, 207, 94, 1)",
    textColor: "#000",
  },
  {
    name: "Reggaeton",
    color: "rgba(69, 196, 179, 0.6)",
    colorHover: "rgba(69, 196, 179, 1)",
    textColor: "#000",
  },
  {
    name: "Road-trip",
    color: "rgba(150, 196, 69, 0.6)",
    colorHover: "rgba(150, 196, 69, 1)",
    textColor: "#000",
  },
  {
    name: "Rock",
    color: "rgba(207, 94, 190, 0.6)",
    colorHover: "rgba(207, 94, 190, 1)",
    textColor: "#000",
  },
  {
    name: "Rock-n-roll",
    color: "rgba(201, 83, 74, 0.6)",
    colorHover: "rgba(201, 83, 74, 1)",
    textColor: "#fff",
  },
  {
    name: "Rockabilly",
    color: "rgba(74, 129, 201, 0.6)",
    colorHover: "rgba(74, 129, 201, 1)",
    textColor: "#fff",
  },
  {
    name: "Romance",
    color: "rgba(207, 94, 152, 0.6)",
    colorHover: "rgba(207, 94, 152, 1)",
    textColor: "#000",
  },
  {
    name: "Sad",
    color: "rgba(170, 191, 64, 0.6)",
    colorHover: "rgba(170, 191, 64, 1)",
    textColor: "#000",
  },
  {
    name: "Salsa",
    color: "rgba(64, 191, 106, 0.6)",
    colorHover: "rgba(64, 191, 106, 1)",
    textColor: "#000",
  },
  {
    name: "Samba",
    color: "rgba(74, 129, 201, 0.6)",
    colorHover: "rgba(74, 129, 201, 1)",
    textColor: "#fff",
  },
  {
    name: "Sertanejo",
    color: "rgba(194, 68, 66, 0.6)",
    colorHover: "rgba(194, 68, 66, 1)",
    textColor: "#fff",
  },
  {
    name: "Show-tunes",
    color: "rgba(94, 207, 130, 0.6)",
    colorHover: "rgba(94, 207, 130, 1)",
    textColor: "#000",
  },
  {
    name: "Singer-songwriter",
    color: "rgba(207, 167, 94, 0.6)",
    colorHover: "rgba(207, 167, 94, 1)",
    textColor: "#000",
  },
  {
    name: "Ska",
    color: "rgba(202, 121, 88, 0.6)",
    colorHover: "rgba(202, 121, 88, 1)",
    textColor: "#000",
  },
  {
    name: "Sleep",
    color: "rgba(188, 83, 198, 0.6)",
    colorHover: "rgba(188, 83, 198, 1)",
    textColor: "#fff",
  },
  {
    name: "Songwriter",
    color: "rgba(152, 205, 91, 0.6)",
    colorHover: "rgba(152, 205, 91, 1)",
    textColor: "#000",
  },
  {
    name: "Soul",
    color: "rgba(207, 205, 94, 0.6)",
    colorHover: "rgba(207, 205, 94, 1)",
    textColor: "#000",
  },
  {
    name: "Soundtracks",
    color: "rgba(192, 199, 72, 0.6)",
    colorHover: "rgba(192, 199, 72, 1)",
    textColor: "#000",
  },
  {
    name: "Spanish",
    color: "rgba(94, 69, 196, 0.6)",
    colorHover: "rgba(94, 69, 196, 1)",
    textColor: "#fff",
  },
  {
    name: "Study",
    color: "rgba(88, 202, 197, 0.6)",
    colorHover: "rgba(88, 202, 197, 1)",
    textColor: "#000",
  },
  {
    name: "Summer",
    color: "rgba(72, 171, 199, 0.6)",
    colorHover: "rgba(72, 171, 199, 1)",
    textColor: "#000",
  },
  {
    name: "Synth-pop",
    color: "rgba(196, 137, 69, 0.6)",
    colorHover: "rgba(196, 137, 69, 1)",
    textColor: "#000",
  },
  {
    name: "Tango",
    color: "rgba(198, 131, 83, 0.6)",
    colorHover: "rgba(198, 131, 83, 1)",
    textColor: "#000",
  },
  {
    name: "Techno",
    color: "rgba(88, 132, 202, 0.6)",
    colorHover: "rgba(88, 132, 202, 1)",
    textColor: "#fff",
  },
  {
    name: "Trance",
    color: "rgba(90, 66, 194, 0.6)",
    colorHover: "rgba(90, 66, 194, 1)",
    textColor: "#fff",
  },
  {
    name: "Trip-hop",
    color: "rgba(198, 83, 112, 0.6)",
    colorHover: "rgba(198, 83, 112, 1)",
    textColor: "#fff",
  },
  {
    name: "Work-out",
    color: "rgba(196, 73, 69, 0.6)",
    colorHover: "rgba(196, 73, 69, 1)",
    textColor: "#fff",
  },
  {
    name: "World-music",
    color: "rgba(64, 191, 85, 0.6)",
    colorHover: "rgba(64, 191, 85, 1)",
    textColor: "#000",
  },
];
